<template>
  <div class="container-action-button">
    <BaseButton @click="onDeleteArticleFamily" buttonText="" title="Supprimer">
      <template v-slot:iconBefore>
        <font-awesome-icon :icon="['fas', 'trash']" />
      </template>
    </BaseButton>
    <BaseButton @click="onEditArticleFamily" buttonText="" title="Modifier la famille">
      <template v-slot:iconBefore>
        <font-awesome-icon :icon="['fas', 'pen']" />
      </template>
    </BaseButton>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import * as Vue from "vue";
import BaseButton from "../../components/Base/BaseButton.vue";

export default {
  name: "ListArticleFamiliesActionButtonsRenderer",
  components: {
    BaseButton: Vue.shallowRef(BaseButton),
    FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon),
  },
  methods: {
    onEditArticleFamily() {
      this.params.context.componentParent.displayEditArticleFamilyModal(this.params.data);
    },
    onDeleteArticleFamily() {
      this.params.context.componentParent.deleteArticleFamily(this.params.data);
    },
  },
};
</script>