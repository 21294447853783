<style>
.full-width {
  width: 100%;
}
</style>

<template>
  <ButtonTopbar
    buttonText="Créer une famille d'article"
    @onTopbarButtonClicked="displayCreateArticleFamilyModal"
  >
    <template v-slot:iconBefore>
      <font-awesome-icon :icon="['fas', 'plus']" />
    </template>
  </ButtonTopbar>
  <Aggrid
    apiRoute="articleFamily/list"
    :apiParams="filter"
    :columnDefs="columnDefs"
    :frameworkComponents="frameworkComponents"
    :context="context"
    aggridHeightSubstract="100px"
  >
    <template v-slot:filter>
      <div>
        <BaseInput
          v-model="search"
          label="Rechercher une famille d'article"
          type="text"
          name="search"
          @onChange="onSearchInputChange"
        >
          <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'search']" />
          </template>
        </BaseInput>
      </div>
    </template>
  </Aggrid>
  <Modal v-show="displayModalCreateOrEditArticleFamily === true">
    <template v-slot:modalIcon>
      <font-awesome-icon
        :icon="['fas', createOrEditMode === 'create' ? 'plus' : 'edit']"
      />
    </template>
    <template v-slot:modalTitle>
      <div v-show="createOrEditMode === 'create'">
        CRÉER UNE FAMILLE D'ARTICLE
      </div>
      <div v-show="createOrEditMode === 'edit'">
        MODIFIER UNE FAMILLE D'ARTICLE
      </div>
    </template>
    <template v-slot:modalContent>
      <form class="form-column">
        <div class="full-width">
          <BaseInput
            v-model="articleFamily.label"
            label="Libellé"
            type="text"
            tabIndex="1"
            name="label"
            validator="text"
            :error="formErrors?.label?.error?.message"
            @onChange="onFormInputChange"
            @blur="handleOnBlurInput"
            :displayError="displayError"
          />
          <BaseInput
            v-model="articleFamily.code"
            label="Code"
            type="text"
            tabIndex="2"
            name="code"
            validator="text"
            :error="formErrors?.buyAccount?.error?.message"
            @onChange="onFormInputChange"
            :displayError="displayError"
            :disabled="createOrEditMode !== 'create'"
          />
          <BaseInput
            v-model="articleFamily.order"
            label="Ordre d'affichage"
            type="number"
            tabIndex="3"
            name="order"
            validator="number"
            :error="formErrors?.saleAccount?.error?.message"
            @onChange="onFormInputChange"
            :displayError="displayError"
          />
        </div>
      </form>
    </template>
    <template v-slot:modalButtons>
      <BaseButton class="white-button" @click="closeModal" buttonText="Fermer">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'times']" />
        </template>
      </BaseButton>
      <BaseButton
        class="orange-button"
        @click="submitModal"
        buttonText="Valider"
      >
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'check']" />
        </template>
      </BaseButton>
    </template>
  </Modal>
  <Dialog ref="dialog" />
</template>

<script>
import axios from "axios";
import Aggrid from "../../components/Aggrid/Aggrid";
import ListTextWithIconRenderer from "../../components/Aggrid/ListTextWithIconRenderer";
import Dialog from "../../components/App/Dialog";
import ListWithDelete from "../../components/App/ListWithDelete";
import Modal from "../../components/App/Modal";
import RadioButtonContainer from "../../components/App/RadioButtonContainer";
import BaseButton from "../../components/Base/BaseButton";
import BaseInput from "../../components/Base/BaseInput";
import BaseRadioButton from "../../components/Base/BaseRadioButton";
import BaseSelect from "../../components/Base/BaseSelect";
import ButtonTopbar from "../../components/Topbar/ButtonTopbar";
import ListArticleFamiliesActionButtonsRenderer from "./ListArticleFamiliesActionButtonsRenderer";

export default {
  name: "ArticleFamily",
  components: {
    ButtonTopbar,
    BaseButton,
    BaseInput,
    BaseSelect,
    BaseRadioButton,
    RadioButtonContainer,
    ListWithDelete,
    ListTextWithIconRenderer,
    ListArticleFamiliesActionButtonsRenderer,
    Aggrid,
    Modal,
    Dialog,
  },
  data() {
    return {
      columnDefs: [
        { field: "label", headerName: `Libellé`, flex: 1 },
        { field: "code", headerName: `Code`, flex: 1 },
        { field: "order", headerName: `Ordre d'affichage`, flex: 1, filter: 'agNumberColumnFilter' },
        {
          headerName: "",
          width: 130,
          cellRenderer: "ListArticleFamiliesActionButtonsRenderer",
          suppressMenu: true
        },
      ],
      frameworkComponents: {
        ListTextWithIconRenderer: ListTextWithIconRenderer,
        ListArticleFamiliesActionButtonsRenderer:
          ListArticleFamiliesActionButtonsRenderer,
      },
      context: null,
      displayModalCreateOrEditArticleFamily: false,
      createOrEditMode: null,
      search: "",
      searchTimeout: null,
      articleFamily: null,
      filter: {
        search: "",
      },
      displayError: false,
      formErrors: {},
    };
  },
  beforeMount() {
    this.context = {
      componentParent: this,
    };

    this.resetArticleFamily();
  },
  methods: {
    closeModal() {
      this.resetArticleFamily();
      this.displayModalCreateOrEditArticleFamily = false;
      this.displayError = false;
    },
    submitModal() {
      if (this.createOrEditMode === "create") {
        this.createArticleFamily();
      } else if (this.createOrEditMode === "edit") {
        this.editArticleFamily();
      }
    },
    displayCreateArticleFamilyModal() {
      this.createOrEditMode = "create";
      this.resetArticleFamily();
      this.displayModalCreateOrEditArticleFamily = true;
    },
    displayEditArticleFamilyModal(articleFamily) {
      this.createOrEditMode = "edit";
      this.articleFamily = JSON.parse(JSON.stringify(articleFamily));
      this.displayModalCreateOrEditArticleFamily = true;
    },
    resetArticleFamily() {
      this.articleFamily = {
        label: null,
        code: null,
        order: null,
      };
    },
    formateArticleFamily(articleFamily) {
      return JSON.parse(JSON.stringify(articleFamily));
    },
    createArticleFamily() {
      if (!this.checkForm()) return;

      axios
        .post(
          "articleFamily/create",
          this.formateArticleFamily(this.articleFamily),
          {
            toastSuccessMessage: `Famille d'article créée`,
            toastError: true,
            showPreloader: true,
          }
        )
        .then(() => {
          this.closeModal();
          this.emitter.emit("ag-grid-reload");
        })
        .catch(() => {});
    },
    editArticleFamily() {
      if (!this.checkForm()) return;

      axios
        .put(
          "articleFamily/update",
          this.formateArticleFamily(this.articleFamily),
          {
            toastSuccessMessage: `Modification effectuée`,
            toastError: true,
            showPreloader: true,
          }
        )
        .then(() => {
          this.closeModal();
          this.emitter.emit("ag-grid-reload");
        })
        .catch(() => {});
    },
    deleteArticleFamily(articleFamily) {
      this.$refs.dialog
        .show({
          type: "confirm",
          title: "Confirmation",
          message: `Êtes-vous sûr de vouloir ${
            articleFamily.deleted ? "ré-activer" : "supprimer"
          } cette famille d'article ?`,
        })
        .then(() => {
          axios
            .put(
              "articleFamily/delete/" + articleFamily.id,
              {
                deleted: !articleFamily.deleted,
              },
              {
                toastSuccessMessage: `Modification effectuée`,
                toastError: true,
                showPreloader: true,
              }
            )
            .then(() => {
              this.emitter.emit("ag-grid-reload");
            });
        })
        .catch(() => {});
    },
    onFormInputChange(input) {
      if (input.error?.message !== null) {
        this.formErrors[input.name] = input;
      } else {
        delete this.formErrors[input.name];
      }
    },
    handleOnBlurInput(e) {
      if (e.target.value !== null) {
        this.articleFamily.code = e.target.value.toUpperCase().substr(0, 3);
      }
    },
    checkForm() {
      this.displayError = true;

      return Object.keys(this.formErrors).length === 0;
    },
    onSearchInputChange(input) {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        this.filter.search = input.value;
      }, 250);
    },
  },
};
</script>